import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/FormPreView/:token/:formResourceId/:random',
    name: 'FormPreView',
    component: () => import('../views/FormPreView.vue')
  },
  {
    path: '/form/:token/:random',
    name: 'MainForm',
    component: () => import('../views/MainForm.vue')
  },
  {
    path: '/FormFill/:token/:procDefId/:formId/:random',
    name: 'FormFill',
    component: () => import('../views/FormFill.vue')
  },
  {
    path: '/FormPush/:token/:processDefinitionId/:random',
    name: 'FormPush',
    component: () => import('../views/FormPush.vue')
  },
  {
    path: '/FormEdit/:token/:formResourceId/:formResourceName/:category/:formResourceCode/:random',
    name: 'FormEdit',
    component: () => import('../views/FormEdit.vue')
  },
  {
    path: '/FormProcessTask/:token/:processDefinitionId/:actId/:taskId/:name/:procInsId/:random',
    name: 'FormProcessTask',
    component: () => import('../views/FormProcessTask.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
