<template>
    <div></div>
</template>

<script>

export default {
   name:'HookComponent',
   mounted(){
  let data = { height: document.getElementsByTagName("form")[0].scrollHeight };
  window.parent.postMessage(data, '*');
   } 
}
</script>
