import Vue from "vue";
import App from "./App.vue";
import KFormDesign from './components/k-form-design/packages'
import './components/k-form-design/styles/form-design.less'
import router from './router'


Vue.config.productionTip = false;
Vue.use(KFormDesign);
new Vue({
  router,
  render: (h) => h(App)
}).$mount("#app");
